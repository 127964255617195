






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TaskAssignment } from '@/classes/TaskAssignments.resource';

@Component({})
export default class DotListItem extends Vue {
	@Prop({ default: null })
	assignment: TaskAssignment;

	@Prop({ default: null })
	taskId: number;

	@Prop({ default: '' })
	taskColor: string;

	displayDotToolTip = false;
}
